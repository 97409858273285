<template>
  <div id="app">
    <PaymentPage />
  </div>
</template>

<script>
import PaymentPage from './pages/PaymentPage.vue';

export default {
  components: {
    PaymentPage
  }
};
</script>

<style>
/* Add your global styles here */
</style>